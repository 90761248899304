<template>
  <div class="bg-white">
    <div class="ml-auto py-4 px-4 w-full sm:px-6 lg:pl-8 lg:py-4">
      <div class="space-y-12">
        <div
          class="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none"
        >
          <h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl">
            Directory
          </h2>
          <p class="text-xl text-gray-500"></p>
        </div>
        <!-- <pre>
            {{users}}
        </pre> -->
        <ul
          class="
            space-y-12
            sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0
            lg:grid-cols-3 lg:gap-x-8
            justify-between
            pb-4
          "
        >
          <a :href="user.url" v-for="user in users" :key="user.name">
            <li>
              <div>
                <!-- <div class="aspect-w-1 aspect-h-1">
                <img
                  class="object-cover shadow-lg rounded-lg"
                  :src="getImgUrl(user.imageUrl)"
                  alt=""
                />
              </div> -->

                <div class="bg-red-400 p-2 rounded flex">
                  <div class="text-lg leading-6 font-medium space-y-1">
                    <h3 class="text-xl mb-2 text-gray-900 font-bold">
                      <span class="border-b-2 border-black">{{
                        `${user.firstName} ${user.lastName}`
                      }}</span>
                    </h3>
                    <p class="text-gray-700">{{ user.organization }}</p>
                    <ul class="flex space-x-5">
                      <!-- <li>
                    <a :href="user.twitterUrl" class="text-gray-400 hover:text-gray-500">
                      <span class="sr-only">Twitter</span>
                      <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                      </svg>
                    </a>
                  </li> -->
                      <li>
                        <a
                          :href="user.websiteUrl"
                          class="text-gray-400 hover:text-gray-500"
                        >
                          <span class="sr-only">Website</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-globe w-5"
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="2" y1="12" x2="22" y2="12"></line>
                            <path
                              d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"
                            ></path>
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="relative bg-green-300 pl-2/3 h-48">
                    <img
                      class="absolute w-full h-full object-cover"
                      :src="`${$store.state.API_URL}${user.avatar}`"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </li>
          </a>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "../helper";
import store from "../store/index";

const people = [
  {
    name: "Dr. Xiuzhen Huang",
    role: "Director, Center for No-Boundary Thinking",
    imageUrl: "img/xhuang.jpg",
    websiteUrl:
      "https://www.astate.edu/college/ecs/people/people-details.dot?pid=95023da5-a256-4dd5-9e7d-b55ba68a2a9c",
  },
];

const onSiteMentors = [
  {
    name: "Karl Walker",
    title: "Chair, Assoc. Prof.",
    institution: "Univ. of Arkansas at Pine Bluff",
    expertise: "Bioinf., AI, database",
  },
];

export default {
  setup() {
    return {
      people,
      onSiteMentors,
    };
  },
  data() {
    return {
      users: [],
    };
  },
  methods: {
    setData(data) {
      this.users = data.profiles;
    },
  },
  async beforeRouteEnter(to, from, next) {
    console.log(this);
    if (!store.state.isLoggedIn) {
      next({ name: "Login" });
    }
    const res = await get("/users");
    console.log(res);
    if (res.ok) {
      const data = await res.json();
      next((vm) => vm.setData(data));
    } else {
      next({ name: "NotFound" });
    }
  },
  async beforeRouteUpdate() {
    try {
      const res = await get("/users");
      console.log(res);
      if (res.ok) {
        const data = await res.json();
        this.user = this.setData(data);
      } else {
        this.$router.push({ name: "NotFound" });
      }
    } catch (error) {
      this.error = error.toString();
    }
  },
};
</script>
